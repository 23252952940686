import React, { useContext, useState, useEffect } from 'react';
import { RicosContext, RicosViewerContext } from 'ricos-context';
import { LINK_PREVIEW_TYPE } from './viewer-loadable.js';
import { debounce } from 'lodash';
import { Node_Type } from 'ricos-types';
import { getHost } from 'wix-rich-content-common';
import HtmlComponent from 'wix-rich-content-plugin-html/libs/HtmlComponent';
import { Image } from 'wix-rich-content-ui-components';
import 'ricos-schema';
import '@loadable/component';

var styles = {"fontElementMap":"J-XLL","linkPreview":"hP8LB","linkPreviewImage":"T2rBB","linkPreviewInfo":"JAtWi","linkPreviewTitle":"o7YuZ","ellipsis":"vEsyu","linkPreviewDescription":"_61qAn","linkPreviewUrl":"_1n4QG"};

function _createForOfIteratorHelperLoose(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) {
    for (var _iterator = _createForOfIteratorHelperLoose(__getOwnPropSymbols(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
    }
  }
  return a;
};
var __spreadProps = function __spreadProps(a, b) {
  return __defProps(a, __getOwnPropDescs(b));
};
var LinkPreviewViewer = function LinkPreviewViewer(_ref) {
  var componentData = _ref.componentData,
    settings = _ref.settings,
    iframeSandboxDomain = _ref.iframeSandboxDomain,
    helpers = _ref.helpers,
    nodeId = _ref.nodeId;
  var _a;
  var _settings$enableEmbed = settings.enableEmbed,
    enableEmbed = _settings$enableEmbed === void 0 ? true : _settings$enableEmbed;
  return enableEmbed && ((_a = componentData.oembed) == null ? void 0 : _a.html) ? /* @__PURE__ */React.createElement(SocialEmbedViewer, {
    componentData: componentData,
    settings: settings,
    iframeSandboxDomain: iframeSandboxDomain
  }) : /* @__PURE__ */React.createElement(LinkPreviewCard, {
    nodeId: nodeId,
    componentData: componentData,
    helpers: helpers
  });
};
var SocialEmbedViewer = function SocialEmbedViewer(_ref2) {
  var componentData = _ref2.componentData,
    settings = _ref2.settings,
    iframeSandboxDomain = _ref2.iframeSandboxDomain;
  var _useContext = useContext(RicosContext),
    theme = _useContext.theme,
    isMobile = _useContext.isMobile;
  var htmlCompProps = {
    componentData: __spreadProps(__spreadValues({}, componentData), {
      html: unescape(componentData.oembed.html)
    }),
    settings: settings,
    theme: theme,
    isMobile: isMobile,
    iframeSandboxDomain: iframeSandboxDomain
  };
  return /* @__PURE__ */React.createElement(HtmlComponent, __spreadValues({}, htmlCompProps));
};
var LinkPreviewCard = function LinkPreviewCard(_ref3) {
  var componentData = _ref3.componentData,
    helpers = _ref3.helpers,
    nodeId = _ref3.nodeId;
  var _a;
  var _useState = useState(0),
    imageHeight = _useState[0],
    setImageHeight = _useState[1];
  var _useState2 = useState(null),
    elementRef = _useState2[0],
    setElementRef = _useState2[1];
  var _useContext2 = useContext(RicosContext),
    isMobile = _useContext2.isMobile;
  var linkPreviewStyles = styles;
  useEffect(function () {
    if (!elementRef || !(window == null ? void 0 : window.ResizeObserver)) {
      return;
    }
    var resizer = new ResizeObserver(debounce(function (entries) {
      setImageHeight(Math.round(entries[0].contentRect.height));
    }, 60));
    resizer.observe(elementRef);
    return function () {
      resizer.unobserve(elementRef);
    };
  }, [elementRef]);
  var getUrlForDisplay = function getUrlForDisplay(url) {
    return url.replace(/^https?:\/\//, "");
  };
  var onLinkPreviewClick = function onLinkPreviewClick() {
    var _a2;
    return (_a2 = helpers == null ? void 0 : helpers.onViewerAction) == null ? void 0 : _a2.call(helpers, LINK_PREVIEW_TYPE, "Click", componentData.link.url || "", nodeId, {
      type: Node_Type.LINK_PREVIEW,
      linkPreviewData: componentData
    });
  };
  var title = componentData.title,
    description = componentData.description,
    thumbnailUrl = componentData.thumbnailUrl;
  var linkPreview = linkPreviewStyles.linkPreview,
    linkPreviewUrl = linkPreviewStyles.linkPreviewUrl,
    linkPreviewInfo = linkPreviewStyles.linkPreviewInfo,
    linkPreviewTitle = linkPreviewStyles.linkPreviewTitle,
    linkPreviewImage = linkPreviewStyles.linkPreviewImage,
    linkPreviewDescription = linkPreviewStyles.linkPreviewDescription;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    /* @__PURE__ */
    React.createElement("figure", {
      className: linkPreview,
      "data-hook": "linkPreviewViewer",
      onClick: onLinkPreviewClick,
      ref: setElementRef
    }, thumbnailUrl && /* @__PURE__ */React.createElement(Image, {
      uri: thumbnailUrl,
      containerProps: {
        style: {
          height: imageHeight,
          width: isMobile ? 110 : imageHeight
        },
        className: linkPreviewImage
      },
      height: imageHeight,
      width: isMobile ? 110 : imageHeight,
      displayMode: "fill",
      alt: title
    }), /* @__PURE__ */React.createElement("section", {
      className: linkPreviewInfo
    }, /* @__PURE__ */React.createElement("div", {
      className: linkPreviewUrl
    }, getUrlForDisplay(getHost(componentData.link.url) || ((_a = componentData.link) == null ? void 0 : _a.url))), /* @__PURE__ */React.createElement("figcaption", {
      className: linkPreviewTitle
    }, title), description && /* @__PURE__ */React.createElement("div", {
      className: linkPreviewDescription
    }, description)))
  );
};

var LinkPreview = function LinkPreview(_ref) {
  var node = _ref.node,
    nodeIndex = _ref.nodeIndex,
    NodeViewContainer = _ref.NodeViewContainer;
  var _useContext = useContext(RicosViewerContext),
    helpers = _useContext.helpers,
    iframeSandboxDomain = _useContext.iframeSandboxDomain,
    config = _useContext.config;
  return /* @__PURE__ */React.createElement(NodeViewContainer, {
    node: node,
    nodeIndex: nodeIndex
  }, /* @__PURE__ */React.createElement(LinkPreviewViewer, {
    nodeId: node.id,
    helpers: helpers,
    settings: config[LINK_PREVIEW_TYPE],
    iframeSandboxDomain: iframeSandboxDomain || "",
    componentData: node.linkPreviewData || node.embedData
  }));
};

export { LinkPreview as default };

import 'wix-rich-content-plugin-link-preview/dist/loadable/viewer/es/styles.viewer.min.global.css'